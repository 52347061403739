import AdyenCheckout from '@adyen/adyen-web'
import DropinElement from '@adyen/adyen-web/dist/types/components/Dropin'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { isProduction } from '../utils/env'
import { useAuth } from './useAuth'
import { useCleeng } from './useCleeng'
import { useOffers } from './useOffers'

const ADYEN_REGIONS: Record<string, string> = {
  EU: 'live',
  US: 'live-us',
  AU: 'live-au',
  APSE: 'live-apse',
  IN: 'live-in',
}

export const useCheckout = (
  dropinRef: React.RefObject<HTMLDivElement>,
  couponCode?: string,
) => {
  const { selectedOffer } = useOffers()

  const order = useRef<IOrder>()
  const paymentMethods = useRef<IPaymentMethod[]>()
  const adyenPaymentMethodId = useRef<number>()
  const paypalPaymentMethodId = useRef<number>()
  const dropinComponentRef = useRef<DropinElement>()
  const [paymentLoading, setPaymentLoading] = useState(false)
  const [paymentError, setPaymentError] = useState<string>()

  const {
    createOrder,
    fetchPaymentMethods,
    updateOrder,
    createAdyenPaymentSession,
    createAdyenPayment,
    createPaypalPayment,
  } = useCleeng()
  const { customer, locale, userData } = useAuth()
  const navigate = useNavigate()

  const handleDropinSubmit = useCallback(
    async (onSubmitResult: any) => {
      if (!customer || !adyenPaymentMethodId.current || !order.current) return

      setPaymentLoading(true)
      setPaymentError(undefined)

      await updateOrder(
        order.current.id,
        adyenPaymentMethodId.current,
        customer.jwt,
      )

      const result = await createAdyenPayment(
        order.current.id,
        onSubmitResult,
        customer.jwt,
      )

      if (!result?.responseData?.payment) {
        if (result?.errors?.length) {
          setPaymentError(result.errors[0])
        }

        dropinComponentRef.current?.setElementStatus('ready')
        setPaymentLoading(false)

        return
      }

      if (isProduction()) {
        await fetch('https://hooks.zapier.com/hooks/catch/9963214/22gpvwd/', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
          },
          body: JSON.stringify({
            ...userData,
            conversionUrl: window.location.href,
          }),
        })
      }

      navigate('/success')
    },
    [createAdyenPayment, customer, navigate, updateOrder, userData],
  )

  const handleClickPaypal = async () => {
    if (!customer || !paypalPaymentMethodId.current || !order.current) return

    setPaymentLoading(true)

    await updateOrder(
      order.current.id,
      paypalPaymentMethodId.current,
      customer.jwt,
    )

    const result = await createPaypalPayment(order.current.id, customer.jwt)

    const redirectUrl = result.responseData?.redirectUrl

    if (redirectUrl) {
      window.location.href = redirectUrl
    } else {
      setPaymentLoading(false)
    }
  }

  const initAdyen = async () => {
    if (!customer) return

    const { responseData: adyenPaymentMethodsResponse } =
      await createAdyenPaymentSession(customer.jwt)

    if (!adyenPaymentMethodsResponse) {
      setPaymentLoading(false)

      return
    }

    const applePayConfiguration =
      adyenPaymentMethodsResponse.paymentMethods.find(
        (p) => p.type === 'applepay',
      )?.configuration

    const googlePayConfiguration =
      adyenPaymentMethodsResponse.paymentMethods.find(
        (p) => p.type === 'googlepay',
      )?.configuration

    const isParamountPage = document.body.classList.contains('page-paramount')

    const configuration: Parameters<typeof AdyenCheckout>[0] = {
      environment: isProduction()
        ? ADYEN_REGIONS[adyenPaymentMethodsResponse.region]
        : 'test',
      clientKey: isProduction()
        ? 'live_BQDOFBYTGZB3XKF62GBYSLPUJ4YW2TPL'
        : 'test_I4OFGUUCEVB5TI222AS3N2Y2LY6PJM3K',
      onError: (error, component) => {
        console.error(error.name, error.message, error.stack, component)
      },
      analytics: {
        enabled: false,
      },
      translations: {
        'en-US': {
          'payButton.saveDetails': isParamountPage
            ? 'CONFIRM & PAY'
            : 'Start Trial',
        },
      },
      paymentMethodsResponse: adyenPaymentMethodsResponse,
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
        },
        ...(applePayConfiguration
          ? {
              applepay: {
                amount: adyenPaymentMethodsResponse.amount,
                countryCode: adyenPaymentMethodsResponse.countryCode,
                configuration: {
                  merchantName: adyenPaymentMethodsResponse.shopperStatement,
                  merchantId: applePayConfiguration.merchantId,
                },
                buttonType: 'plain',
                buttonColor: 'black',
              },
            }
          : {}),
        ...(googlePayConfiguration
          ? {
              googlepay: {
                amount: adyenPaymentMethodsResponse.amount,
                countryCode: adyenPaymentMethodsResponse.countryCode,
                environment: isProduction() ? 'PRODUCTION' : 'TEST',
                configuration: {
                  merchantName: adyenPaymentMethodsResponse.shopperStatement,
                  gatewayMerchantId: googlePayConfiguration.gatewayMerchantId,
                  merchantId: googlePayConfiguration.merchantId,
                },
                buttonType: 'buy',
                buttonColor: 'white',
                buttonSizeMode: 'static',
              },
            }
          : {}),
      },
      onSubmit: handleDropinSubmit,
    }

    const checkout = await AdyenCheckout(configuration)

    const dropinComponent = checkout.create('dropin', {
      onSelect: (paymentMethod) => {
        const paymentMethodId = paymentMethods.current?.find(
          (p) => p.methodName === paymentMethod.type,
        )?.id

        console.log('Set payment method ID to', paymentMethodId)

        adyenPaymentMethodId.current = paymentMethodId
      },
    })
    dropinComponent.mount(dropinRef.current!)

    dropinComponentRef.current = dropinComponent

    setPaymentLoading(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      if (!customer) return

      const paymentMethodsBody = await fetchPaymentMethods(customer.jwt)
      paymentMethods.current =
        paymentMethodsBody.responseData?.paymentMethods || []

      const paypal = paymentMethods.current.find(
        (p) => p.paymentGateway === 'paypal',
      )

      initAdyen()

      paypalPaymentMethodId.current = paypal?.id
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!selectedOffer || !customer) return

    const fetchData = async () => {
      setPaymentLoading(true)
      order.current = undefined

      const orderBody = await createOrder(
        selectedOffer.offerId,
        customer.customerId,
        locale,
        customer.jwt,
        couponCode,
      )

      const o = orderBody.responseData?.order

      order.current = o

      setPaymentLoading(false)
    }

    fetchData()
  }, [selectedOffer])

  return {
    order: order.current,
    paymentError,
    paymentLoading,
    handleClickPaypal,
  }
}
