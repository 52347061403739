import { useEffect } from 'react'
import { useOffers } from '../../hooks/useOffers'
import { CheckoutPage } from '../CheckoutPage'

export const PetsvivoCheckout: React.FC = () => {
  const { offers, setIsPickOfferFirstFlow, setSelectedOffer } =
    useOffers()

  useEffect(() => {
    if (offers?.length) {
      setIsPickOfferFirstFlow(true)
      setSelectedOffer(offers[0])
    }
  }, [offers, setIsPickOfferFirstFlow, setSelectedOffer])

  return (
    <CheckoutPage
      couponCode="PETSVIVO"
      couponComponent={
        <div className="coupon-display" >
          <div><span className='textbefore'>Coupon Code Applied:</span> <span className='coupontext'>PETSVIVO</span></div>
        </div>
      }
      //bannerImage='/checkoutLeft.png'
      backgroundColor="var(--Bone)"
      titleOverride="MONTHLY (1-MONTH FREE TRIAL)"
    />
  )
}
