// src/utils/useUtmHandler.tsx
import * as React from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface UtmParams {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
  [key: string]: string | undefined;
}

const UTM_PARAMS_KEY = 'utm_params';
const PROCESSING_KEY = 'processing_utm';

function getStoredUtmParams(): UtmParams | null {
  try {
    const params = sessionStorage.getItem(UTM_PARAMS_KEY);
    return params ? JSON.parse(params) : null;
  } catch (e) {
    console.error('Error getting UTM params:', e);
    return null;
  }
}

function checkUtmParam(param: string): boolean {
  const params = getStoredUtmParams();
  return !!(params && params[param]);
}

function scrollToAnchor(hash: string) {
  if (!hash) return;
  
  const id = hash.replace('#', '');
  const element = document.getElementById(id);
  
  if (element) {
    setTimeout(() => {
      element.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  }
}

const useUtmHandler = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Handle initial load anchor
  useEffect(() => {
    if (location.hash) {
      scrollToAnchor(location.hash);
    }
  }, [location.hash]);

  // Handle anchor clicks
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      const anchor = target.closest('a[href^="#"]');
      
      if (anchor) {
        e.preventDefault();
        const hash = anchor.getAttribute('href') || '';
        scrollToAnchor(hash);

        // Preserve UTMs when updating hash
        const currentUtms = new URLSearchParams(window.location.search);
        const utmString = currentUtms.toString();
        const newUrl = `${window.location.pathname}${utmString ? `?${utmString}` : ''}${hash}`;
        window.history.pushState(null, '', newUrl);
      }
    };

    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  // Handle UTM parameters
  useEffect(() => {
    if (sessionStorage.getItem(PROCESSING_KEY)) return;

    try {
      sessionStorage.setItem(PROCESSING_KEY, 'true');
      
      const searchParams = new URLSearchParams(window.location.search);
      const utmParams: UtmParams = {};
      let hasUtms = false;

      searchParams.forEach((value, key) => {
        if (key.startsWith('utm_')) {
          utmParams[key] = value;
          hasUtms = true;
        }
      });

      if (hasUtms) {
        sessionStorage.setItem(UTM_PARAMS_KEY, JSON.stringify(utmParams));
      } else {
        const storedUtms = getStoredUtmParams();
        if (storedUtms && Object.keys(storedUtms).length > 0) {
          const currentParams = new URLSearchParams(location.search);
          Object.entries(storedUtms).forEach(([key, value]) => {
            if (value) currentParams.set(key, value);
          });
          
          const newSearch = currentParams.toString();
          const hash = location.hash;
          const newPath = `${location.pathname}${newSearch ? `?${newSearch}` : ''}${hash}`;
          
          navigate(newPath, { replace: true });
        }
      }
    } finally {
      sessionStorage.removeItem(PROCESSING_KEY);
    }
  }, [location.pathname]);
};

type UtmHandlerProps = {
  children: React.ReactNode;
}

const UtmHandler: React.FC<UtmHandlerProps> = ({ children }) => {
  useUtmHandler();
  return React.createElement(React.Fragment, null, children);
};

export {
  UtmHandler,
  useUtmHandler,
  getStoredUtmParams as getUtmParams,
  checkUtmParam as hasUtmParam,
  type UtmParams
};